<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.WarehouseSubTransferStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="保税商品">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                @keyup.enter.native="$refs.table.refresh(true)"
                allow-clear
                v-model="queryParam.bonded_commodity_id"
                placeholder="保税商品"
                @search="handleBondedCommoditySearch"
                @change="handleBondedCommodityChange"
              >
                <a-select-option v-for="d in dataSource_bonded_commodity" :key="d.id" :tt="d.bonded_goods.name + '(' + d.batch+ ')'">
                  {{ d.bonded_goods.name + '(' + d.batch+ ')' }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" class="goodslist">
            <a-form-item label="出区仓库">
              <a-auto-complete
                v-model="queryParam.out_warehouse_id"
                placeholder="请输入"
                @keyup.enter.native="$refs.table.refresh(true)"
                @search="onSearchOutWarehouse"
              >
                <template slot="dataSource">
                  <a-select-option v-for="item in dataSourceOutWarehouse" :tt="item.name" :key="item.id + ''">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-auto-complete>
              <span>{{ tip_out_warehouse }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" class="goodslist">
            <a-form-item label="入区仓库">
              <a-auto-complete
                v-model="queryParam.in_warehouse_id"
                placeholder="请输入"
                @keyup.enter.native="$refs.table.refresh(true)"
                @search="onSearchOutWarehouse"
              >
                <template slot="dataSource">
                  <a-select-option v-for="item in dataSourceOutWarehouse" :tt="item.name" :key="item.id + ''">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-auto-complete>
              <span>{{ tip_in_warehouse }}</span>
            </a-form-item>
          </a-col>

          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 80px; max-width: 90px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDelet(record)" v-if="record.status === 1">删除</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a @click="handleUpdate(record, 2)" v-if="record.status === 1">出库</a>
          <a @click="handleUpdate(record, 3)" v-if="record.status === 2">入库</a>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import {
  bonded_warehouse_list,
  bonded_warehouse_sub_transfer_delete,
  bonded_warehouse_sub_transfer_list, bonded_warehouse_sub_transfer_update
} from '@/api/bonded_warehouse'
import { bonded_commodity_list } from '@/api/bonded_commodity'

export default {
  name: 'Read',
  components: {
    STable,
    AAutoComplete
  },
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      tip_in_warehouse: '',
      dataSourceCommodity: [],
      tip_commodity: '',
      dataSourceOutWarehouse: [],
      exhibition_warehouse_value: '',
      exhibition_goods_value: '',
      dataSource_exhibition_warehouse: [],
      dataSource_exhibition_goods: [],
      bonded_commodity_value: '',
      dataSource_bonded_commodity: [],
      tip_out_warehouse: '',
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货品名',
          dataIndex: 'bonded_goods',
          customRender: (text) => text.name,
          ellipsis: true
        },
        {
          title: '数量',
          dataIndex: 'count',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '出库仓库',
          dataIndex: 'out_warehouse',
          ellipsis: true
        },
        {
          title: '出库位',
          dataIndex: 'out_cargo_space',
          ellipsis: true
        },
        {
          title: '入库仓库',
          dataIndex: 'in_warehouse',
          ellipsis: true
        },
        {
          title: '入库位',
          dataIndex: 'in_cargo_space',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.WarehouseSubTransferStatus[text],
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return bonded_warehouse_sub_transfer_list(Object.assign(parameter, this.queryParam, { transfer_id: this.$route.query.id }))
          .then(res => {
            return res.data
          })
      }
    }
  },
  created () {},
  methods: {
    handleDelet (record) {
      bonded_warehouse_sub_transfer_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleUpdate (record, target) {
      bonded_warehouse_sub_transfer_update({ target: target }, record.id).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    //  commodity
    handleBondedCommoditySearch (value) {
      bonded_commodity_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        console.log(result, '98989')
        this.dataSource_bonded_commodity = !value ? [] : result
      })
    },
    handleBondedCommodityChange (value) {
      this.queryParam.bonded_commodity_id = value
      this.bonded_commodity_value = value
    },
    //  out
    onSearchOutWarehouse (searchText) {
      bonded_warehouse_list({ name: searchText }).then((res) => {
        const result = res.data.entries || []
        if (result.length === 0) {
          this.tip_out_warehouse = '未找到您输入的保税商品'
        } else {
          this.tip_out_warehouse = ''
        }
        this.dataSourceOutWarehouse = !searchText ? [] : result
      })
    }
  }
}
</script>
<style lang="less">
    .goodslist .ant-select-selection--single .ant-select-selection__rendered {
      margin-right: 0px;
    }
    .goodslist .ant-select-selection__rendered {
      position: relative;
      display: block;
      margin-right: 0px;
      margin-left: 0px;
      line-height: 30px;
    }
</style>
