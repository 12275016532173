import request from '@/utils/request'

const bonded_warehouseApi = {
  bonded_warehouse_list: '/bonded_warehouse/',
  bonded_warehouse_create: '/bonded_warehouse/',
  bonded_warehouse_update: '/bonded_warehouse/',
  bonded_warehouse_delete: '/bonded_warehouse/',
  bonded_warehouse_bonded_commodity_list: '/bonded_warehouse/bonded_commodity/',
  bonded_warehouse_corporation_list: '/bonded_warehouse/corporation/',
  bonded_warehouse_corporation_create: '/bonded_warehouse/corporation/',
  bonded_warehouse_corporation_delete: '/bonded_warehouse/corporation/',
  bonded_warehouse_transfer_list: '/bonded_warehouse/transfer/',
  bonded_warehouse_transfer_create: '/bonded_warehouse/transfer/',
  bonded_warehouse_transfer_delete: '/bonded_warehouse/transfer/',
  bonded_warehouse_sub_transfer_list: '/bonded_warehouse/sub_transfer/',
  bonded_warehouse_sub_transfer_create: '/bonded_warehouse/sub_transfer/',
  bonded_warehouse_sub_transfer_update: '/bonded_warehouse/sub_transfer/',
  bonded_warehouse_sub_transfer_delete: '/bonded_warehouse/sub_transfer/'
}

/**
 * 列表
 */
export function bonded_warehouse_list (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_warehouse_create (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bonded_warehouse_update (parameter, bonded_warehouse_id) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_update + bonded_warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_warehouse_delete (bonded_warehouse_id) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_delete + bonded_warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 列表
 */
export function bonded_warehouse_bonded_commodity_list (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_bonded_commodity_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function bonded_warehouse_corporation_list (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_corporation_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_warehouse_corporation_create (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_corporation_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function bonded_warehouse_corporation_delete (bonded_warehouse_id) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_corporation_delete + bonded_warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 列表
 */
export function bonded_warehouse_transfer_list (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_transfer_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_warehouse_transfer_create (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_transfer_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function bonded_warehouse_transfer_delete (bonded_warehouse_id) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_transfer_delete + bonded_warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function bonded_warehouse_sub_transfer_list (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_sub_transfer_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_warehouse_sub_transfer_create (parameter) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_sub_transfer_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bonded_warehouse_sub_transfer_update (parameter, bonded_warehouse_id) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_sub_transfer_update + bonded_warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_warehouse_sub_transfer_delete (bonded_warehouse_id) {
  return request({
    url: bonded_warehouseApi.bonded_warehouse_sub_transfer_delete + bonded_warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
